import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppDataService } from './app_data.service';

@Injectable()
export class AddressValidationService {
  countriesWithPostalCode: string[]= ['DZ', 'AR', 'AM', 'AU', 'AT', 'AZ', 'A2', 'BD', 'BY', 'BE', 'BA', 'BR', 'BN', 'BG', 'CA', 'IC', 'CN', 'HR', 'CY', 'CZ', 'DK', 'EN', 'EE', 'FO', 'FI', 'FR', 'GE', 'DE', 'GR', 'GL', '    GU', 'GG', 'HO', 'HU', 'IN', 'ID', 'IL', 'IT', 'JP', 'JE', 'KZ', 'KR', 'KO', 'KG', 'LV', 'LI', 'LT', 'LU', 'MK', 'MG', 'M3', 'MY', 'MH', 'MQ', 'YT', 'MX', 'MN', 'ME', 'NL', 'NZ', 'N    B', 'NO', 'PK', 'PH', 'PL', 'PO', 'PT', 'PR', 'RE', 'RU', 'SA', 'SF', 'CS', 'SG', 'SK', 'SI', 'ZA', 'ES', 'LK', 'NT', 'SX', 'UV', 'VL', 'SE', 'CH', 'TW', 'TJ', 'TH', 'TU', 'TN', 'TR    ', 'TM', 'VI', 'UA', 'GB', 'US', 'UY', 'UZ', 'VA', 'VN', 'WL', 'YA'];
  constructor(private appDataService: AppDataService) { }

  buildAddressFormGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      first_name: ['', [Validators.required, Validators.maxLength(30)]],
      last_name: ['', [Validators.required, Validators.maxLength(30)]],
      company_name: ['', Validators.maxLength(100)],
      line_1: ['', [Validators.required, Validators.maxLength(100)]],
      line_2: ['', Validators.maxLength(100)],
      line_3: ['', Validators.maxLength( 100)],
      city: ['', [Validators.required, Validators.maxLength(30)]],
      country_code: ['', [Validators.required]],
      zip_code_extension: ['', Validators.maxLength(4)],
      postal_code: [''],
      state_province: ['', Validators.maxLength(30)],
      is_default: false,
      sync: false,
      attention: ['', [Validators.maxLength(30)]],
      additional_instructions: ['']
    });
  }

  clearCountryValidators(form) {
    form.controls['state_province'].clearValidators();
    form.controls['postal_code'].clearValidators();
    form.controls['postal_code'].clearAsyncValidators();
  }

  subscribeToCountryChanges(addressForm) {
    const countryControl = addressForm.controls.country_code;
    const changes$ = countryControl.valueChanges;
    changes$.subscribe( country_code => {
      this.handleCountryCode(addressForm, country_code);
    });
  }

  handleCountryCode(addressForm, country_code) {
    this.clearCountryValidators(addressForm);
    if (country_code === 'US') {
      addressForm.controls['postal_code'].setValidators([
        Validators.required, Validators.pattern(/^\d*$/), Validators.maxLength(5), Validators.minLength(5)]);
      addressForm.controls['state_province'].setValidators([Validators.required]);

      const match = this.appDataService.stateList.find((val) => {
          return val.code === addressForm.controls['state_province'].value;
      });

      if(typeof(match) === 'undefined') {
          addressForm.controls['state_province'].reset();
      }

    } else if (country_code === 'CA') {
      addressForm.controls['postal_code'].setValidators([
        Validators.required, Validators.pattern(/(\w{3}) (\w{3})/), Validators.maxLength(7)
      ]);


      const match = this.appDataService.provinceList.find((val) => {
        return val.code === addressForm.controls['state_province'].value;
      });

      if(typeof(match) === 'undefined') {
        addressForm.controls['state_province'].reset();
      }
    } else {
      if (this.countriesWithPostalCode.includes(country_code)) {
        addressForm.controls['postal_code'].setValidators([Validators.maxLength(20)]);
        addressForm.controls['state_province'].setValidators(Validators.maxLength(30));
      }
      if(addressForm.value.state_province !== '' && addressForm.value.country_code !== 'CA' && addressForm.value.country_code !== 'US') {
        addressForm.controls['state_province'].setValue(addressForm.value.state_province);
      } else {
        addressForm.controls['state_province'].reset();
      }
    }
    addressForm.controls['state_province'].updateValueAndValidity();
    addressForm.controls['zip_code_extension'].updateValueAndValidity();
    addressForm.controls['postal_code'].updateValueAndValidity();
  }

}
